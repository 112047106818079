import grpcClient from '~/grpc/grpc-client'
import {
  SavePreRegisterRequest,
  SaveAddressRequest,
  ListCountryRequest,
  ListCountryResponse,
  ListIndustryRequest,
  ListIndustryResponse,
  ListEmployeeNumberRequest,
  ListEmployeeNumberResponse,
  SaveCorporationInformationBuyerRequest,
  SaveMzhcCorporationInformationBuyerRequest,
  UpdateRegistrationPurposeRequest,
  UpdatePrivateCorporationRequest,
  SaveCorporationInformationProviderRequest,
  UpdatePreRegisterByTokenRequest,
  UpdatePreRegisterByTokenResponse,
  SaveOrderFormatRequest,
  SaveContractStyleRequest,
  GetPreRegisterEmailRequest,
  GetIsMzhcRequest,
  GenerateUserNameRequest,
  GenerateUserNameResponse,
  ListBudgetResponse,
  ListBudgetRequest,
  SaveBudgetRequest,
  SaveBudgetResponse,
  ListDesiredWorkingConditionItemsRequest,
  ListDesiredWorkingConditionItemsResponse,
  SaveDesiredWorkingConditionRequest
} from '~/stub/gw/auth/register_service_pb'
import { RegisterService } from '~/stub/gw/auth/register_service_pb_service'
import GrpcAccessorBase from '../grpc-accessor-base'
import { Stripped, toStringValue, toInt32Value, toBoolValue } from '~/grpc/grpc-util'
import { NullableField } from '~/types/util'

export type UpdatePreRegisterByToken = Stripped<UpdatePreRegisterByTokenResponse.AsObject>
export type ListIndustry = Stripped<ListIndustryResponse.AsObject['industryDomainList']>
export type ListCountry = Stripped<ListCountryResponse.AsObject['countriesList']>
export type ListEmployeeNumber = Stripped<
  ListEmployeeNumberResponse.AsObject['employeeNumberDomainList']
>
export type ListBudgetType = Stripped<ListBudgetResponse.AsObject>
export type SaveBudgetRequestType = Stripped<SaveBudgetRequest.AsObject>
export type GetIsMzhcRequestType = Stripped<GetIsMzhcRequest.AsObject['preAuthHash']>
export type MzhcFormInfo = Stripped<
  Omit<SaveMzhcCorporationInformationBuyerRequest.AsObject, 'prefecture'> & {
    prefecture?: string | null
  }
>
export type ListDesiredWorkingConditionItemsType =
  Stripped<ListDesiredWorkingConditionItemsResponse.AsObject>
export type DesiredWorkingConditionType = NullableField<
  Stripped<SaveDesiredWorkingConditionRequest.AsObject>,
  'weeklyWorkingDays' | 'dailyWorkingHour' | 'minHourlyPay' | 'minMonthlyPay'
>
class RegisterAccessor extends GrpcAccessorBase {
  registerEmail(params: Stripped<SavePreRegisterRequest.AsObject>, ref: string | null) {
    const request = new SavePreRegisterRequest()
    request.setEmail(toStringValue(params.email))
    request.setRegistrationPurpose(params.registrationPurpose)
    request.setPrivateCorporation(params.privateCorporation)
    if (params.campaign) request.setCampaign(toStringValue(params.campaign))
    if (params.inviteCode) request.setInviteCode(toStringValue(params.inviteCode))
    request.setInflowRoute(params.inflowRoute)
    if (params.redirectUrl) request.setRedirectUrl(toStringValue(params.redirectUrl))
    if (params.isMzhc) request.setIsMzhc(toBoolValue(params.isMzhc))
    if (params.a8AffiliateId) request.setA8AffiliateId(toStringValue(params.a8AffiliateId))
    if (params.linkEdgeAffiliateId)
      request.setLinkEdgeAffiliateId(toStringValue(params.linkEdgeAffiliateId))
    if (params.accessTradeAffiliateId)
      request.setAccessTradeAffiliateId(toStringValue(params.accessTradeAffiliateId))
    if (params.affiliCodeAffiliateId)
      request.setAffiliCodeAffiliateId(toStringValue(params.affiliCodeAffiliateId))
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify({
      ref,
      type: 'Email'
    })
    return grpcClient({
      method: RegisterService.SavePreRegister,
      request,
      metadata,
      strip: true
    })
  }

  saveAddress(params: Stripped<SaveAddressRequest.AsObject>, detail) {
    const request = new SaveAddressRequest()
    request.setPostalCode(toStringValue(params.postalCode))
    request.setPrefecture(toStringValue(params.prefecture))
    request.setAddress(toStringValue(params.address))
    if (params.addressBuilding) request.setAddressBuilding(toStringValue(params.addressBuilding))
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify(detail)
    return grpcClient({
      method: RegisterService.SaveAddress,
      request,
      metadata,
      strip: true
    })
  }

  saveCorporationInformationBuyer(
    params: Stripped<SaveCorporationInformationBuyerRequest.AsObject>,
    detail
  ) {
    const request = new SaveCorporationInformationBuyerRequest()
    request.setCorporateName(toStringValue(params.corporateName))
    request.setIndustryId(toInt32Value(params.industryId))
    request.setEmployeeNumberId(toInt32Value(params.employeeNumberId))
    request.setPhoneNumber(toStringValue(params.phoneNumber))
    if (params.enterpriseInvitationKey) {
      request.setEnterpriseInvitationKey(toStringValue(params.enterpriseInvitationKey))
    }
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify(detail)
    return grpcClient({
      method: RegisterService.SaveCorporationInformationBuyer,
      request,
      metadata,
      strip: true
    })
  }

  saveMzhcCorporationInformationBuyer(params: MzhcFormInfo, detail) {
    const request = new SaveMzhcCorporationInformationBuyerRequest()
    request.setCorporateName(toStringValue(params.corporateName))
    request.setIndustryId(toInt32Value(params.industryId))
    request.setEmployeeNumberId(toInt32Value(params.employeeNumberId))
    request.setPhoneNumber(toStringValue(params.phoneNumber))

    request.setRepresentativeName(toStringValue(params.representativeName))
    request.setPostalCode(toStringValue(params.postalCode))
    if (params.prefecture) request.setPrefecture(toStringValue(params.prefecture))
    request.setAddress(toStringValue(params.address))
    request.setBranchCode(toStringValue(params.branchCode))
    request.setBankAccountNumber(toStringValue(params.bankAccountNumber))
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify(detail)
    return grpcClient({
      method: RegisterService.SaveMzhcCorporationInformationBuyer,
      request,
      metadata
    })
  }

  saveCorporationInformationProvider(
    params: Stripped<SaveCorporationInformationProviderRequest.AsObject>,
    detail
  ) {
    const request = new SaveCorporationInformationProviderRequest()
    request.setCorporateName(toStringValue(params.corporateName))
    request.setPostalCode(toStringValue(params.postalCode))
    request.setPrefecture(toStringValue(params.prefecture))
    request.setAddress(toStringValue(params.address))
    if (params.addressBuilding) request.setAddressBuilding(toStringValue(params.addressBuilding))
    request.setPhoneNumber(toStringValue(params.phoneNumber))
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify(detail)
    return grpcClient({
      method: RegisterService.SaveCorporationInformationProvider,
      request,
      metadata,
      strip: true
    })
  }

  confirmPreAuthHash(hashToken: string): Promise<UpdatePreRegisterByToken> {
    const request = new UpdatePreRegisterByTokenRequest()
    request.setPreAuthHash(toStringValue(hashToken))
    return grpcClient({
      method: RegisterService.UpdatePreRegisterByToken,
      request,
      metadata: this.getMetadata(),
      strip: true
    })
  }

  fetchPreRegisterEmail(hashToken: string) {
    const request = new GetPreRegisterEmailRequest()
    request.setPreAuthHash(toStringValue(hashToken))
    return grpcClient({
      method: RegisterService.GetPreRegisterEmail,
      request,
      metadata: this.getMetadata(),
      strip: true
    })
  }

  getIsMzhc(hashToken: GetIsMzhcRequestType) {
    const request = new GetIsMzhcRequest()
    request.setPreAuthHash(toStringValue(hashToken))
    return grpcClient({
      method: RegisterService.GetIsMzhc,
      request,
      metadata: this.getMetadata(),
      strip: true
    })
  }

  async fetchCountry(): Promise<ListCountry> {
    const request = new ListCountryRequest()
    const response = await grpcClient({
      method: RegisterService.ListCountry,
      request,
      metadata: this.getMetadata(),
      strip: true
    })
    return response.countriesList
  }

  async fetchListIndustry(): Promise<ListIndustry> {
    const request = new ListIndustryRequest()
    const response = await grpcClient({
      method: RegisterService.ListIndustry,
      request,
      metadata: this.getMetadata(),
      strip: true
    })
    return response.industryDomainList
  }

  async fetchListEmployeeNumber(): Promise<ListEmployeeNumber> {
    const request = new ListEmployeeNumberRequest()
    const response = await grpcClient({
      method: RegisterService.ListEmployeeNumber,
      request,
      metadata: this.getMetadata(),
      strip: true
    })
    return response.employeeNumberDomainList
  }

  updateRegistrationPurpose(params: Stripped<UpdateRegistrationPurposeRequest.AsObject>, detail) {
    const request = new UpdateRegistrationPurposeRequest()
    request.setPreAuthHash(toStringValue(params.preAuthHash))
    request.setPurpose(params.purpose)
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify(detail)
    return grpcClient({
      method: RegisterService.UpdateRegistrationPurpose,
      request,
      metadata,
      strip: true
    })
  }

  updatePrivateCorporation(params: Stripped<UpdatePrivateCorporationRequest.AsObject>, detail) {
    const request = new UpdatePrivateCorporationRequest()
    request.setPreAuthHash(toStringValue(params.preAuthHash))
    request.setPrivateCorporation(params.privateCorporation)
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify(detail)
    return grpcClient({
      method: RegisterService.UpdatePrivateCorporation,
      request,
      metadata,
      strip: true
    })
  }

  saveContractStyle(params: Stripped<SaveContractStyleRequest.AsObject>, detail) {
    const request = new SaveContractStyleRequest()
    request.setContractStyle(params.contractStyle)
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify(detail)
    return grpcClient({
      method: RegisterService.SaveContractStyle,
      request,
      metadata,
      strip: true
    })
  }

  saveOrderFormat(params: Stripped<SaveOrderFormatRequest.AsObject>, detail) {
    const request = new SaveOrderFormatRequest()
    request.setOrderFormat(params.orderFormat)
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify(detail)
    return grpcClient({
      method: RegisterService.SaveOrderFormat,
      request,
      metadata,
      strip: true
    })
  }

  generateUserName(): Promise<GenerateUserNameResponse.AsObject> {
    const method = RegisterService.GenerateUserName
    const request = new GenerateUserNameRequest()
    const metadata = this.getMetadata()
    return grpcClient({ method, request, metadata })
  }

  fetchListBudget(): Promise<ListBudgetType> {
    const request = new ListBudgetRequest()
    return grpcClient({
      method: RegisterService.ListBudget,
      request,
      metadata: this.getMetadata(),
      strip: true
    })
  }

  saveBudget(params: SaveBudgetRequestType, detail: object): Promise<SaveBudgetResponse.AsObject> {
    const request = new SaveBudgetRequest()
    request.setBudgetType(toInt32Value(params.budgetType))
    request.setPlannedTime(toInt32Value(params.plannedTime))

    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify(detail)
    return grpcClient({
      method: RegisterService.SaveBudget,
      request,
      metadata
    })
  }

  fetchListDesiredWorkingConditionItems(): Promise<ListDesiredWorkingConditionItemsType> {
    const request = new ListDesiredWorkingConditionItemsRequest()
    return grpcClient({
      method: RegisterService.ListDesiredWorkingConditionItems,
      request,
      metadata: this.getMetadata(),
      strip: true
    })
  }

  saveDesiredWorkingCondition(
    params: Stripped<SaveDesiredWorkingConditionRequest.AsObject>,
    detail
  ) {
    const request = new SaveDesiredWorkingConditionRequest()
    request.setMinHourlyPay(params.minHourlyPay)
    request.setMinMonthlyPay(params.minMonthlyPay)
    request.setWeeklyWorkingDays(params.weeklyWorkingDays)
    request.setDailyWorkingHour(params.dailyWorkingHour)
    request.setWorkingTimeframesList(params.workingTimeframesList)
    request.setWorkingLocationsList(params.workingLocationsList)
    const metadata = this.getMetadata()
    metadata['x-log-detail'] = JSON.stringify(detail)
    return grpcClient({
      method: RegisterService.SaveDesiredWorkingCondition,
      request,
      metadata,
      strip: true
    })
  }
}

export default RegisterAccessor
