// source: domain/enumeration/entry_form_contract_style.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle = {
  ENTRY_FORM_CONTRACT_STYLE_UNSPECIFIED: 0,
  ENTRY_FORM_CONTRACT_STYLE_SINGLE: 1,
  ENTRY_FORM_CONTRACT_STYLE_SUBCONTRACTING: 2,
  ENTRY_FORM_CONTRACT_STYLE_BOTH: 3,
  ENTRY_FORM_CONTRACT_STYLE_NOT_DETERMINED: 99
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
