// source: gw/auth/register_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var domain_enumeration_entry_form_status_pb = require('../../domain/enumeration/entry_form_status_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_status_pb);
var domain_enumeration_entry_form_purpose_type_pb = require('../../domain/enumeration/entry_form_purpose_type_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_purpose_type_pb);
var domain_enumeration_entry_form_organization_type_pb = require('../../domain/enumeration/entry_form_organization_type_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_organization_type_pb);
var domain_enumeration_entry_form_inflow_route_pb = require('../../domain/enumeration/entry_form_inflow_route_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_inflow_route_pb);
var domain_enumeration_entry_form_order_format_pb = require('../../domain/enumeration/entry_form_order_format_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_order_format_pb);
var domain_enumeration_entry_form_contract_style_pb = require('../../domain/enumeration/entry_form_contract_style_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_contract_style_pb);
var domain_register_pb = require('../../domain/register_pb.js');
goog.object.extend(proto, domain_register_pb);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.GenerateUserNameRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.GenerateUserNameResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.GetIsMzhcRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.GetIsMzhcResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.ListBudgetRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.ListBudgetResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.ListCountryRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.ListCountryResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.ListIndustryRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.ListIndustryResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveAddressRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveAddressResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveBudgetRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveBudgetResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveContractStyleRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveContractStyleResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SavePreRegisterRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.SavePreRegisterResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SavePreRegisterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.displayName = 'proto.coconala.protobuf.gw.auth.SavePreRegisterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SavePreRegisterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.displayName = 'proto.coconala.protobuf.gw.auth.SavePreRegisterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.displayName = 'proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.displayName = 'proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.displayName = 'proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.displayName = 'proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.displayName = 'proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse.displayName = 'proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.displayName = 'proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse.displayName = 'proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.displayName = 'proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.displayName = 'proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.GetIsMzhcRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.displayName = 'proto.coconala.protobuf.gw.auth.GetIsMzhcRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.GetIsMzhcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.GetIsMzhcResponse.displayName = 'proto.coconala.protobuf.gw.auth.GetIsMzhcResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveAddressRequest.displayName = 'proto.coconala.protobuf.gw.auth.SaveAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveAddressResponse.displayName = 'proto.coconala.protobuf.gw.auth.SaveAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.displayName = 'proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse.displayName = 'proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.displayName = 'proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse.displayName = 'proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.displayName = 'proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse.displayName = 'proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest.displayName = 'proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse.displayName = 'proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.ListIndustryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.ListIndustryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.ListIndustryRequest.displayName = 'proto.coconala.protobuf.gw.auth.ListIndustryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.ListIndustryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.auth.ListIndustryResponse.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.ListIndustryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.ListIndustryResponse.displayName = 'proto.coconala.protobuf.gw.auth.ListIndustryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest.displayName = 'proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.displayName = 'proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.ListCountryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.ListCountryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.ListCountryRequest.displayName = 'proto.coconala.protobuf.gw.auth.ListCountryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.ListCountryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.auth.ListCountryResponse.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.ListCountryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.ListCountryResponse.displayName = 'proto.coconala.protobuf.gw.auth.ListCountryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.GenerateUserNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.GenerateUserNameRequest.displayName = 'proto.coconala.protobuf.gw.auth.GenerateUserNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.GenerateUserNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.displayName = 'proto.coconala.protobuf.gw.auth.GenerateUserNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveContractStyleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveContractStyleRequest.displayName = 'proto.coconala.protobuf.gw.auth.SaveContractStyleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveContractStyleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveContractStyleResponse.displayName = 'proto.coconala.protobuf.gw.auth.SaveContractStyleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.ListBudgetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.ListBudgetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.ListBudgetRequest.displayName = 'proto.coconala.protobuf.gw.auth.ListBudgetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.auth.ListBudgetResponse.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.ListBudgetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.ListBudgetResponse.displayName = 'proto.coconala.protobuf.gw.auth.ListBudgetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveBudgetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveBudgetRequest.displayName = 'proto.coconala.protobuf.gw.auth.SaveBudgetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveBudgetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveBudgetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveBudgetResponse.displayName = 'proto.coconala.protobuf.gw.auth.SaveBudgetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.displayName = 'proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse.displayName = 'proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest.displayName = 'proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.displayName = 'proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    registrationPurpose: jspb.Message.getFieldWithDefault(msg, 2, 0),
    privateCorporation: jspb.Message.getFieldWithDefault(msg, 3, 0),
    campaign: (f = msg.getCampaign()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    inviteCode: (f = msg.getInviteCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    deviceId: (f = msg.getDeviceId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    inflowRoute: jspb.Message.getFieldWithDefault(msg, 7, 0),
    redirectUrl: (f = msg.getRedirectUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    a8AffiliateId: (f = msg.getA8AffiliateId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    linkEdgeAffiliateId: (f = msg.getLinkEdgeAffiliateId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accessTradeAffiliateId: (f = msg.getAccessTradeAffiliateId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    affiliCodeAffiliateId: (f = msg.getAffiliCodeAffiliateId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isMzhc: (f = msg.getIsMzhc()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SavePreRegisterRequest;
  return proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} */ (reader.readEnum());
      msg.setRegistrationPurpose(value);
      break;
    case 3:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} */ (reader.readEnum());
      msg.setPrivateCorporation(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCampaign(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInviteCode(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDeviceId(value);
      break;
    case 7:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormInflowRoute} */ (reader.readEnum());
      msg.setInflowRoute(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRedirectUrl(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setA8AffiliateId(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLinkEdgeAffiliateId(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccessTradeAffiliateId(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAffiliCodeAffiliateId(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsMzhc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRegistrationPurpose();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPrivateCorporation();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCampaign();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInviteCode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDeviceId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInflowRoute();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getA8AffiliateId();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLinkEdgeAffiliateId();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccessTradeAffiliateId();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAffiliCodeAffiliateId();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsMzhc();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue email = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormPurposeType registration_purpose = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getRegistrationPurpose = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setRegistrationPurpose = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormOrganizationType private_corporation = 3;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getPrivateCorporation = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setPrivateCorporation = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue campaign = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getCampaign = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.clearCampaign = function() {
  return this.setCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue invite_code = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getInviteCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setInviteCode = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.clearInviteCode = function() {
  return this.setInviteCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.hasInviteCode = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue device_id = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getDeviceId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.clearDeviceId = function() {
  return this.setDeviceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.hasDeviceId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormInflowRoute inflow_route = 7;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormInflowRoute}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getInflowRoute = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormInflowRoute} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormInflowRoute} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setInflowRoute = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional google.protobuf.StringValue redirect_url = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getRedirectUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.clearRedirectUrl = function() {
  return this.setRedirectUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.hasRedirectUrl = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue a8_affiliate_id = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getA8AffiliateId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setA8AffiliateId = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.clearA8AffiliateId = function() {
  return this.setA8AffiliateId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.hasA8AffiliateId = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue link_edge_affiliate_id = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getLinkEdgeAffiliateId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setLinkEdgeAffiliateId = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.clearLinkEdgeAffiliateId = function() {
  return this.setLinkEdgeAffiliateId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.hasLinkEdgeAffiliateId = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue access_trade_affiliate_id = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getAccessTradeAffiliateId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setAccessTradeAffiliateId = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.clearAccessTradeAffiliateId = function() {
  return this.setAccessTradeAffiliateId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.hasAccessTradeAffiliateId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue affili_code_affiliate_id = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getAffiliCodeAffiliateId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setAffiliCodeAffiliateId = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.clearAffiliCodeAffiliateId = function() {
  return this.setAffiliCodeAffiliateId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.hasAffiliCodeAffiliateId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.BoolValue is_mzhc = 13;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.getIsMzhc = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 13));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.setIsMzhc = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.clearIsMzhc = function() {
  return this.setIsMzhc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterRequest.prototype.hasIsMzhc = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SavePreRegisterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterResponse}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SavePreRegisterResponse;
  return proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SavePreRegisterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterResponse}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SavePreRegisterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue email = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterResponse} returns this
*/
proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SavePreRegisterResponse} returns this
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SavePreRegisterResponse.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    preAuthHash: (f = msg.getPreAuthHash()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest;
  return proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPreAuthHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreAuthHash();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue pre_auth_hash = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.prototype.getPreAuthHash = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest} returns this
*/
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.prototype.setPreAuthHash = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest} returns this
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.prototype.clearPreAuthHash = function() {
  return this.setPreAuthHash(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenRequest.prototype.hasPreAuthHash = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purpose: jspb.Message.getFieldWithDefault(msg, 1, 0),
    privateCorporation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ccuid: (f = msg.getCcuid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    type: (f = msg.getType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isMzhc: (f = msg.getIsMzhc()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse;
  return proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} */ (reader.readEnum());
      msg.setPurpose(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} */ (reader.readEnum());
      msg.setPrivateCorporation(value);
      break;
    case 3:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCcuid(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsMzhc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurpose();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPrivateCorporation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCcuid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsMzhc();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormPurposeType purpose = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.getPurpose = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} value
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse} returns this
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormOrganizationType private_corporation = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.getPrivateCorporation = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} value
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse} returns this
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.setPrivateCorporation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormStatus status = 3;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormStatus}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.getStatus = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormStatus} value
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse} returns this
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue ccuid = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.getCcuid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse} returns this
*/
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.setCcuid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse} returns this
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.clearCcuid = function() {
  return this.setCcuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.hasCcuid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue type = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse} returns this
*/
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse} returns this
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.hasType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue is_mzhc = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.getIsMzhc = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse} returns this
*/
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.setIsMzhc = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse} returns this
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.clearIsMzhc = function() {
  return this.setIsMzhc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.UpdatePreRegisterByTokenResponse.prototype.hasIsMzhc = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    preAuthHash: (f = msg.getPreAuthHash()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest}
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest;
  return proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest}
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPreAuthHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreAuthHash();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue pre_auth_hash = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.prototype.getPreAuthHash = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest} returns this
*/
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.prototype.setPreAuthHash = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest} returns this
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.prototype.clearPreAuthHash = function() {
  return this.setPreAuthHash(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationRequest.prototype.hasPreAuthHash = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purpose: jspb.Message.getFieldWithDefault(msg, 1, 0),
    privateCorporation: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse}
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse;
  return proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse}
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} */ (reader.readEnum());
      msg.setPurpose(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} */ (reader.readEnum());
      msg.setPrivateCorporation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurpose();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPrivateCorporation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormPurposeType purpose = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType}
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.prototype.getPurpose = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} value
 * @return {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse} returns this
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormOrganizationType private_corporation = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType}
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.prototype.getPrivateCorporation = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} value
 * @return {!proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse} returns this
 */
proto.coconala.protobuf.gw.auth.GetRegistrationPurposeAndPrivateCorporationResponse.prototype.setPrivateCorporation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    preAuthHash: (f = msg.getPreAuthHash()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    purpose: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest}
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest;
  return proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest}
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPreAuthHash(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} */ (reader.readEnum());
      msg.setPurpose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreAuthHash();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPurpose();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue pre_auth_hash = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.prototype.getPreAuthHash = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest} returns this
*/
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.prototype.setPreAuthHash = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest} returns this
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.prototype.clearPreAuthHash = function() {
  return this.setPreAuthHash(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.prototype.hasPreAuthHash = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormPurposeType purpose = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType}
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.prototype.getPurpose = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} value
 * @return {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest} returns this
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeRequest.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse}
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse;
  return proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse}
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.UpdateRegistrationPurposeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    preAuthHash: (f = msg.getPreAuthHash()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    privateCorporation: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest}
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest;
  return proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest}
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPreAuthHash(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} */ (reader.readEnum());
      msg.setPrivateCorporation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreAuthHash();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrivateCorporation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue pre_auth_hash = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.prototype.getPreAuthHash = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest} returns this
*/
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.prototype.setPreAuthHash = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest} returns this
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.prototype.clearPreAuthHash = function() {
  return this.setPreAuthHash(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.prototype.hasPreAuthHash = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormOrganizationType private_corporation = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType}
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.prototype.getPrivateCorporation = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} value
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest} returns this
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationRequest.prototype.setPrivateCorporation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse}
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse;
  return proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse}
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.UpdatePrivateCorporationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    preAuthHash: (f = msg.getPreAuthHash()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest}
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest;
  return proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest}
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPreAuthHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreAuthHash();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue pre_auth_hash = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.prototype.getPreAuthHash = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest} returns this
*/
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.prototype.setPreAuthHash = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest} returns this
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.prototype.clearPreAuthHash = function() {
  return this.setPreAuthHash(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailRequest.prototype.hasPreAuthHash = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse}
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse;
  return proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse}
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue email = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse} returns this
*/
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse} returns this
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.GetPreRegisterEmailResponse.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.GetIsMzhcRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    preAuthHash: (f = msg.getPreAuthHash()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.GetIsMzhcRequest}
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.GetIsMzhcRequest;
  return proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.GetIsMzhcRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.GetIsMzhcRequest}
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPreAuthHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.GetIsMzhcRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreAuthHash();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue pre_auth_hash = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.prototype.getPreAuthHash = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.GetIsMzhcRequest} returns this
*/
proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.prototype.setPreAuthHash = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.GetIsMzhcRequest} returns this
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.prototype.clearPreAuthHash = function() {
  return this.setPreAuthHash(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcRequest.prototype.hasPreAuthHash = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.GetIsMzhcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.GetIsMzhcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isMzhc: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.GetIsMzhcResponse}
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.GetIsMzhcResponse;
  return proto.coconala.protobuf.gw.auth.GetIsMzhcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.GetIsMzhcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.GetIsMzhcResponse}
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMzhc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.GetIsMzhcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.GetIsMzhcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsMzhc();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_mzhc = 1;
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcResponse.prototype.getIsMzhc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.coconala.protobuf.gw.auth.GetIsMzhcResponse} returns this
 */
proto.coconala.protobuf.gw.auth.GetIsMzhcResponse.prototype.setIsMzhc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    postalCode: (f = msg.getPostalCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    prefecture: (f = msg.getPrefecture()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    addressBuilding: (f = msg.getAddressBuilding()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveAddressRequest}
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveAddressRequest;
  return proto.coconala.protobuf.gw.auth.SaveAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveAddressRequest}
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPostalCode(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrefecture(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAddressBuilding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPostalCode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrefecture();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddressBuilding();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue postal_code = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.getPostalCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveAddressRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.setPostalCode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveAddressRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.clearPostalCode = function() {
  return this.setPostalCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.hasPostalCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue prefecture = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.getPrefecture = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveAddressRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.setPrefecture = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveAddressRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.clearPrefecture = function() {
  return this.setPrefecture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.hasPrefecture = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue address = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.getAddress = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveAddressRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveAddressRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue address_building = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.getAddressBuilding = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveAddressRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.setAddressBuilding = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveAddressRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.clearAddressBuilding = function() {
  return this.setAddressBuilding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveAddressRequest.prototype.hasAddressBuilding = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveAddressResponse}
 */
proto.coconala.protobuf.gw.auth.SaveAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveAddressResponse;
  return proto.coconala.protobuf.gw.auth.SaveAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveAddressResponse}
 */
proto.coconala.protobuf.gw.auth.SaveAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corporateName: (f = msg.getCorporateName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    industryId: (f = msg.getIndustryId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    employeeNumberId: (f = msg.getEmployeeNumberId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    phoneNumber: (f = msg.getPhoneNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    enterpriseInvitationKey: (f = msg.getEnterpriseInvitationKey()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest;
  return proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCorporateName(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setIndustryId(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setEmployeeNumberId(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEnterpriseInvitationKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorporateName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIndustryId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getEmployeeNumberId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEnterpriseInvitationKey();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue corporate_name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.getCorporateName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.setCorporateName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.clearCorporateName = function() {
  return this.setCorporateName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.hasCorporateName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value industry_id = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.getIndustryId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.setIndustryId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.clearIndustryId = function() {
  return this.setIndustryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.hasIndustryId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value employee_number_id = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.getEmployeeNumberId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.setEmployeeNumberId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.clearEmployeeNumberId = function() {
  return this.setEmployeeNumberId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.hasEmployeeNumberId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue phone_number = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.getPhoneNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue enterprise_invitation_key = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.getEnterpriseInvitationKey = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.setEnterpriseInvitationKey = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.clearEnterpriseInvitationKey = function() {
  return this.setEnterpriseInvitationKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerRequest.prototype.hasEnterpriseInvitationKey = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse;
  return proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationBuyerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corporateName: (f = msg.getCorporateName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    representativeName: (f = msg.getRepresentativeName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    postalCode: (f = msg.getPostalCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    prefecture: (f = msg.getPrefecture()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    industryId: (f = msg.getIndustryId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    employeeNumberId: (f = msg.getEmployeeNumberId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    phoneNumber: (f = msg.getPhoneNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    branchCode: (f = msg.getBranchCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    bankAccountNumber: (f = msg.getBankAccountNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest;
  return proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCorporateName(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRepresentativeName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPostalCode(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrefecture(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setIndustryId(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setEmployeeNumberId(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBranchCode(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBankAccountNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorporateName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRepresentativeName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPostalCode();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrefecture();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIndustryId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getEmployeeNumberId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBranchCode();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBankAccountNumber();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue corporate_name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.getCorporateName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.setCorporateName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.clearCorporateName = function() {
  return this.setCorporateName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.hasCorporateName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue representative_name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.getRepresentativeName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.setRepresentativeName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.clearRepresentativeName = function() {
  return this.setRepresentativeName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.hasRepresentativeName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue postal_code = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.getPostalCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.setPostalCode = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.clearPostalCode = function() {
  return this.setPostalCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.hasPostalCode = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue prefecture = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.getPrefecture = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.setPrefecture = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.clearPrefecture = function() {
  return this.setPrefecture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.hasPrefecture = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue address = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.getAddress = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value industry_id = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.getIndustryId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.setIndustryId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.clearIndustryId = function() {
  return this.setIndustryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.hasIndustryId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value employee_number_id = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.getEmployeeNumberId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.setEmployeeNumberId = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.clearEmployeeNumberId = function() {
  return this.setEmployeeNumberId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.hasEmployeeNumberId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue phone_number = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.getPhoneNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue branch_code = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.getBranchCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.setBranchCode = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.clearBranchCode = function() {
  return this.setBranchCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.hasBranchCode = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue bank_account_number = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.getBankAccountNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.setBankAccountNumber = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.clearBankAccountNumber = function() {
  return this.setBankAccountNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerRequest.prototype.hasBankAccountNumber = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse;
  return proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveMzhcCorporationInformationBuyerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corporateName: (f = msg.getCorporateName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    postalCode: (f = msg.getPostalCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    prefecture: (f = msg.getPrefecture()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    addressBuilding: (f = msg.getAddressBuilding()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    phoneNumber: (f = msg.getPhoneNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest;
  return proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCorporateName(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPostalCode(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrefecture(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAddressBuilding(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorporateName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPostalCode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrefecture();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddressBuilding();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue corporate_name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.getCorporateName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.setCorporateName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.clearCorporateName = function() {
  return this.setCorporateName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.hasCorporateName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue postal_code = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.getPostalCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.setPostalCode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.clearPostalCode = function() {
  return this.setPostalCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.hasPostalCode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue prefecture = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.getPrefecture = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.setPrefecture = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.clearPrefecture = function() {
  return this.setPrefecture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.hasPrefecture = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue address = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.getAddress = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue address_building = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.getAddressBuilding = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.setAddressBuilding = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.clearAddressBuilding = function() {
  return this.setAddressBuilding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.hasAddressBuilding = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue phone_number = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.getPhoneNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderRequest.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse;
  return proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveCorporationInformationProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderFormat: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest}
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest;
  return proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest}
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} */ (reader.readEnum());
      msg.setOrderFormat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormOrderFormat order_format = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat}
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest.prototype.getOrderFormat = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatRequest.prototype.setOrderFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse}
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse;
  return proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse}
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveOrderFormatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.ListIndustryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.ListIndustryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.ListIndustryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListIndustryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.ListIndustryRequest}
 */
proto.coconala.protobuf.gw.auth.ListIndustryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.ListIndustryRequest;
  return proto.coconala.protobuf.gw.auth.ListIndustryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.ListIndustryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.ListIndustryRequest}
 */
proto.coconala.protobuf.gw.auth.ListIndustryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.ListIndustryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.ListIndustryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.ListIndustryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListIndustryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.auth.ListIndustryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.ListIndustryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.ListIndustryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.ListIndustryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListIndustryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    industryDomainList: jspb.Message.toObjectList(msg.getIndustryDomainList(),
    domain_register_pb.IndustryDomain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.ListIndustryResponse}
 */
proto.coconala.protobuf.gw.auth.ListIndustryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.ListIndustryResponse;
  return proto.coconala.protobuf.gw.auth.ListIndustryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.ListIndustryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.ListIndustryResponse}
 */
proto.coconala.protobuf.gw.auth.ListIndustryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_register_pb.IndustryDomain;
      reader.readMessage(value,domain_register_pb.IndustryDomain.deserializeBinaryFromReader);
      msg.addIndustryDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.ListIndustryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.ListIndustryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.ListIndustryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListIndustryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndustryDomainList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      domain_register_pb.IndustryDomain.serializeBinaryToWriter
    );
  }
};


/**
 * repeated coconala.protobuf.domain.IndustryDomain industry_domain = 1;
 * @return {!Array<!proto.coconala.protobuf.domain.IndustryDomain>}
 */
proto.coconala.protobuf.gw.auth.ListIndustryResponse.prototype.getIndustryDomainList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.IndustryDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_register_pb.IndustryDomain, 1));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.IndustryDomain>} value
 * @return {!proto.coconala.protobuf.gw.auth.ListIndustryResponse} returns this
*/
proto.coconala.protobuf.gw.auth.ListIndustryResponse.prototype.setIndustryDomainList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.IndustryDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.IndustryDomain}
 */
proto.coconala.protobuf.gw.auth.ListIndustryResponse.prototype.addIndustryDomain = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.coconala.protobuf.domain.IndustryDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.auth.ListIndustryResponse} returns this
 */
proto.coconala.protobuf.gw.auth.ListIndustryResponse.prototype.clearIndustryDomainList = function() {
  return this.setIndustryDomainList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest}
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest;
  return proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest}
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    employeeNumberDomainList: jspb.Message.toObjectList(msg.getEmployeeNumberDomainList(),
    domain_register_pb.EmployeeNumberDomain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse}
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse;
  return proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse}
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_register_pb.EmployeeNumberDomain;
      reader.readMessage(value,domain_register_pb.EmployeeNumberDomain.deserializeBinaryFromReader);
      msg.addEmployeeNumberDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmployeeNumberDomainList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      domain_register_pb.EmployeeNumberDomain.serializeBinaryToWriter
    );
  }
};


/**
 * repeated coconala.protobuf.domain.EmployeeNumberDomain employee_number_domain = 1;
 * @return {!Array<!proto.coconala.protobuf.domain.EmployeeNumberDomain>}
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.prototype.getEmployeeNumberDomainList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.EmployeeNumberDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_register_pb.EmployeeNumberDomain, 1));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.EmployeeNumberDomain>} value
 * @return {!proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse} returns this
*/
proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.prototype.setEmployeeNumberDomainList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.EmployeeNumberDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.EmployeeNumberDomain}
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.prototype.addEmployeeNumberDomain = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.coconala.protobuf.domain.EmployeeNumberDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse} returns this
 */
proto.coconala.protobuf.gw.auth.ListEmployeeNumberResponse.prototype.clearEmployeeNumberDomainList = function() {
  return this.setEmployeeNumberDomainList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.ListCountryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.ListCountryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.ListCountryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListCountryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.ListCountryRequest}
 */
proto.coconala.protobuf.gw.auth.ListCountryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.ListCountryRequest;
  return proto.coconala.protobuf.gw.auth.ListCountryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.ListCountryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.ListCountryRequest}
 */
proto.coconala.protobuf.gw.auth.ListCountryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.ListCountryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.ListCountryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.ListCountryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListCountryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.auth.ListCountryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.ListCountryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.ListCountryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.ListCountryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListCountryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    countriesList: jspb.Message.toObjectList(msg.getCountriesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.ListCountryResponse}
 */
proto.coconala.protobuf.gw.auth.ListCountryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.ListCountryResponse;
  return proto.coconala.protobuf.gw.auth.ListCountryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.ListCountryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.ListCountryResponse}
 */
proto.coconala.protobuf.gw.auth.ListCountryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addCountries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.ListCountryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.ListCountryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.ListCountryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListCountryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated google.protobuf.StringValue countries = 1;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.coconala.protobuf.gw.auth.ListCountryResponse.prototype.getCountriesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.coconala.protobuf.gw.auth.ListCountryResponse} returns this
*/
proto.coconala.protobuf.gw.auth.ListCountryResponse.prototype.setCountriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.ListCountryResponse.prototype.addCountries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, google_protobuf_wrappers_pb.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.auth.ListCountryResponse} returns this
 */
proto.coconala.protobuf.gw.auth.ListCountryResponse.prototype.clearCountriesList = function() {
  return this.setCountriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.GenerateUserNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.GenerateUserNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.GenerateUserNameRequest}
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.GenerateUserNameRequest;
  return proto.coconala.protobuf.gw.auth.GenerateUserNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.GenerateUserNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.GenerateUserNameRequest}
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.GenerateUserNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.GenerateUserNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.GenerateUserNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.GenerateUserNameResponse}
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.GenerateUserNameResponse;
  return proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.GenerateUserNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.GenerateUserNameResponse}
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.GenerateUserNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.GenerateUserNameResponse} returns this
*/
proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.GenerateUserNameResponse} returns this
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.GenerateUserNameResponse.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveContractStyleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveContractStyleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractStyle: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveContractStyleRequest}
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveContractStyleRequest;
  return proto.coconala.protobuf.gw.auth.SaveContractStyleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveContractStyleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveContractStyleRequest}
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle} */ (reader.readEnum());
      msg.setContractStyle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveContractStyleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveContractStyleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractStyle();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormContractStyle contract_style = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle}
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleRequest.prototype.getContractStyle = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveContractStyleRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleRequest.prototype.setContractStyle = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveContractStyleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveContractStyleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveContractStyleResponse}
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveContractStyleResponse;
  return proto.coconala.protobuf.gw.auth.SaveContractStyleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveContractStyleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveContractStyleResponse}
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveContractStyleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveContractStyleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveContractStyleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.ListBudgetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.ListBudgetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.ListBudgetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListBudgetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.ListBudgetRequest}
 */
proto.coconala.protobuf.gw.auth.ListBudgetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.ListBudgetRequest;
  return proto.coconala.protobuf.gw.auth.ListBudgetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.ListBudgetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.ListBudgetRequest}
 */
proto.coconala.protobuf.gw.auth.ListBudgetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.ListBudgetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.ListBudgetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.ListBudgetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListBudgetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.ListBudgetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.ListBudgetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderFormat: jspb.Message.getFieldWithDefault(msg, 1, 0),
    budgetTypeDomainList: jspb.Message.toObjectList(msg.getBudgetTypeDomainList(),
    domain_register_pb.RegisterBudgetTypeDomain.toObject, includeInstance),
    plannedTimeDomainList: jspb.Message.toObjectList(msg.getPlannedTimeDomainList(),
    domain_register_pb.RegisterPlannedTimeDomain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.ListBudgetResponse}
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.ListBudgetResponse;
  return proto.coconala.protobuf.gw.auth.ListBudgetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.ListBudgetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.ListBudgetResponse}
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} */ (reader.readEnum());
      msg.setOrderFormat(value);
      break;
    case 2:
      var value = new domain_register_pb.RegisterBudgetTypeDomain;
      reader.readMessage(value,domain_register_pb.RegisterBudgetTypeDomain.deserializeBinaryFromReader);
      msg.addBudgetTypeDomain(value);
      break;
    case 3:
      var value = new domain_register_pb.RegisterPlannedTimeDomain;
      reader.readMessage(value,domain_register_pb.RegisterPlannedTimeDomain.deserializeBinaryFromReader);
      msg.addPlannedTimeDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.ListBudgetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.ListBudgetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBudgetTypeDomainList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_register_pb.RegisterBudgetTypeDomain.serializeBinaryToWriter
    );
  }
  f = message.getPlannedTimeDomainList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      domain_register_pb.RegisterPlannedTimeDomain.serializeBinaryToWriter
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormOrderFormat order_format = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat}
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.prototype.getOrderFormat = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} value
 * @return {!proto.coconala.protobuf.gw.auth.ListBudgetResponse} returns this
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.prototype.setOrderFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated coconala.protobuf.domain.RegisterBudgetTypeDomain budget_type_domain = 2;
 * @return {!Array<!proto.coconala.protobuf.domain.RegisterBudgetTypeDomain>}
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.prototype.getBudgetTypeDomainList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.RegisterBudgetTypeDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_register_pb.RegisterBudgetTypeDomain, 2));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.RegisterBudgetTypeDomain>} value
 * @return {!proto.coconala.protobuf.gw.auth.ListBudgetResponse} returns this
*/
proto.coconala.protobuf.gw.auth.ListBudgetResponse.prototype.setBudgetTypeDomainList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.RegisterBudgetTypeDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.RegisterBudgetTypeDomain}
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.prototype.addBudgetTypeDomain = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.coconala.protobuf.domain.RegisterBudgetTypeDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.auth.ListBudgetResponse} returns this
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.prototype.clearBudgetTypeDomainList = function() {
  return this.setBudgetTypeDomainList([]);
};


/**
 * repeated coconala.protobuf.domain.RegisterPlannedTimeDomain planned_time_domain = 3;
 * @return {!Array<!proto.coconala.protobuf.domain.RegisterPlannedTimeDomain>}
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.prototype.getPlannedTimeDomainList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.RegisterPlannedTimeDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_register_pb.RegisterPlannedTimeDomain, 3));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.RegisterPlannedTimeDomain>} value
 * @return {!proto.coconala.protobuf.gw.auth.ListBudgetResponse} returns this
*/
proto.coconala.protobuf.gw.auth.ListBudgetResponse.prototype.setPlannedTimeDomainList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.RegisterPlannedTimeDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.RegisterPlannedTimeDomain}
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.prototype.addPlannedTimeDomain = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.coconala.protobuf.domain.RegisterPlannedTimeDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.auth.ListBudgetResponse} returns this
 */
proto.coconala.protobuf.gw.auth.ListBudgetResponse.prototype.clearPlannedTimeDomainList = function() {
  return this.setPlannedTimeDomainList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveBudgetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveBudgetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    budgetType: (f = msg.getBudgetType()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    plannedTime: (f = msg.getPlannedTime()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveBudgetRequest}
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveBudgetRequest;
  return proto.coconala.protobuf.gw.auth.SaveBudgetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveBudgetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveBudgetRequest}
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setBudgetType(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPlannedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveBudgetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveBudgetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBudgetType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPlannedTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value budget_type = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.prototype.getBudgetType = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveBudgetRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.prototype.setBudgetType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveBudgetRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.prototype.clearBudgetType = function() {
  return this.setBudgetType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.prototype.hasBudgetType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value planned_time = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.prototype.getPlannedTime = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveBudgetRequest} returns this
*/
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.prototype.setPlannedTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.auth.SaveBudgetRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.prototype.clearPlannedTime = function() {
  return this.setPlannedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.auth.SaveBudgetRequest.prototype.hasPlannedTime = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveBudgetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveBudgetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveBudgetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveBudgetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveBudgetResponse}
 */
proto.coconala.protobuf.gw.auth.SaveBudgetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveBudgetResponse;
  return proto.coconala.protobuf.gw.auth.SaveBudgetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveBudgetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveBudgetResponse}
 */
proto.coconala.protobuf.gw.auth.SaveBudgetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveBudgetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveBudgetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveBudgetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveBudgetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    minHourlyPay: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minMonthlyPay: jspb.Message.getFieldWithDefault(msg, 2, 0),
    weeklyWorkingDays: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dailyWorkingHour: jspb.Message.getFieldWithDefault(msg, 4, 0),
    workingTimeframesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    workingLocationsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest;
  return proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinHourlyPay(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinMonthlyPay(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeeklyWorkingDays(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDailyWorkingHour(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setWorkingTimeframesList(value);
      break;
    case 6:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setWorkingLocationsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinHourlyPay();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMinMonthlyPay();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getWeeklyWorkingDays();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDailyWorkingHour();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getWorkingTimeframesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
  f = message.getWorkingLocationsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 min_hourly_pay = 1;
 * @return {number}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.getMinHourlyPay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.setMinHourlyPay = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 min_monthly_pay = 2;
 * @return {number}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.getMinMonthlyPay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.setMinMonthlyPay = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 weekly_working_days = 3;
 * @return {number}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.getWeeklyWorkingDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.setWeeklyWorkingDays = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 daily_working_hour = 4;
 * @return {number}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.getDailyWorkingHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.setDailyWorkingHour = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated int32 working_timeframes = 5;
 * @return {!Array<number>}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.getWorkingTimeframesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.setWorkingTimeframesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.addWorkingTimeframes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.clearWorkingTimeframesList = function() {
  return this.setWorkingTimeframesList([]);
};


/**
 * repeated int32 working_locations = 6;
 * @return {!Array<number>}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.getWorkingLocationsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.setWorkingLocationsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.addWorkingLocations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest} returns this
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionRequest.prototype.clearWorkingLocationsList = function() {
  return this.setWorkingLocationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse;
  return proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.SaveDesiredWorkingConditionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest;
  return proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    weeklyWorkingDaysDomainsList: jspb.Message.toObjectList(msg.getWeeklyWorkingDaysDomainsList(),
    domain_register_pb.WeeklyWorkingDaysDomain.toObject, includeInstance),
    dailyWorkingHourDomainsList: jspb.Message.toObjectList(msg.getDailyWorkingHourDomainsList(),
    domain_register_pb.DailyWorkingHourDomain.toObject, includeInstance),
    workingTimeframesDomainsList: jspb.Message.toObjectList(msg.getWorkingTimeframesDomainsList(),
    domain_register_pb.WorkingTimeframesDomain.toObject, includeInstance),
    workingLocationsDomainsList: jspb.Message.toObjectList(msg.getWorkingLocationsDomainsList(),
    domain_register_pb.WorkingLocationsDomain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse;
  return proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_register_pb.WeeklyWorkingDaysDomain;
      reader.readMessage(value,domain_register_pb.WeeklyWorkingDaysDomain.deserializeBinaryFromReader);
      msg.addWeeklyWorkingDaysDomains(value);
      break;
    case 2:
      var value = new domain_register_pb.DailyWorkingHourDomain;
      reader.readMessage(value,domain_register_pb.DailyWorkingHourDomain.deserializeBinaryFromReader);
      msg.addDailyWorkingHourDomains(value);
      break;
    case 3:
      var value = new domain_register_pb.WorkingTimeframesDomain;
      reader.readMessage(value,domain_register_pb.WorkingTimeframesDomain.deserializeBinaryFromReader);
      msg.addWorkingTimeframesDomains(value);
      break;
    case 4:
      var value = new domain_register_pb.WorkingLocationsDomain;
      reader.readMessage(value,domain_register_pb.WorkingLocationsDomain.deserializeBinaryFromReader);
      msg.addWorkingLocationsDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeeklyWorkingDaysDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      domain_register_pb.WeeklyWorkingDaysDomain.serializeBinaryToWriter
    );
  }
  f = message.getDailyWorkingHourDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_register_pb.DailyWorkingHourDomain.serializeBinaryToWriter
    );
  }
  f = message.getWorkingTimeframesDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      domain_register_pb.WorkingTimeframesDomain.serializeBinaryToWriter
    );
  }
  f = message.getWorkingLocationsDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      domain_register_pb.WorkingLocationsDomain.serializeBinaryToWriter
    );
  }
};


/**
 * repeated coconala.protobuf.domain.WeeklyWorkingDaysDomain weekly_working_days_domains = 1;
 * @return {!Array<!proto.coconala.protobuf.domain.WeeklyWorkingDaysDomain>}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.getWeeklyWorkingDaysDomainsList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.WeeklyWorkingDaysDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_register_pb.WeeklyWorkingDaysDomain, 1));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.WeeklyWorkingDaysDomain>} value
 * @return {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse} returns this
*/
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.setWeeklyWorkingDaysDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.WeeklyWorkingDaysDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.WeeklyWorkingDaysDomain}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.addWeeklyWorkingDaysDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.coconala.protobuf.domain.WeeklyWorkingDaysDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse} returns this
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.clearWeeklyWorkingDaysDomainsList = function() {
  return this.setWeeklyWorkingDaysDomainsList([]);
};


/**
 * repeated coconala.protobuf.domain.DailyWorkingHourDomain daily_working_hour_domains = 2;
 * @return {!Array<!proto.coconala.protobuf.domain.DailyWorkingHourDomain>}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.getDailyWorkingHourDomainsList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.DailyWorkingHourDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_register_pb.DailyWorkingHourDomain, 2));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.DailyWorkingHourDomain>} value
 * @return {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse} returns this
*/
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.setDailyWorkingHourDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.DailyWorkingHourDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.DailyWorkingHourDomain}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.addDailyWorkingHourDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.coconala.protobuf.domain.DailyWorkingHourDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse} returns this
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.clearDailyWorkingHourDomainsList = function() {
  return this.setDailyWorkingHourDomainsList([]);
};


/**
 * repeated coconala.protobuf.domain.WorkingTimeframesDomain working_timeframes_domains = 3;
 * @return {!Array<!proto.coconala.protobuf.domain.WorkingTimeframesDomain>}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.getWorkingTimeframesDomainsList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.WorkingTimeframesDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_register_pb.WorkingTimeframesDomain, 3));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.WorkingTimeframesDomain>} value
 * @return {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse} returns this
*/
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.setWorkingTimeframesDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.WorkingTimeframesDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.WorkingTimeframesDomain}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.addWorkingTimeframesDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.coconala.protobuf.domain.WorkingTimeframesDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse} returns this
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.clearWorkingTimeframesDomainsList = function() {
  return this.setWorkingTimeframesDomainsList([]);
};


/**
 * repeated coconala.protobuf.domain.WorkingLocationsDomain working_locations_domains = 4;
 * @return {!Array<!proto.coconala.protobuf.domain.WorkingLocationsDomain>}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.getWorkingLocationsDomainsList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.WorkingLocationsDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_register_pb.WorkingLocationsDomain, 4));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.WorkingLocationsDomain>} value
 * @return {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse} returns this
*/
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.setWorkingLocationsDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.WorkingLocationsDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.WorkingLocationsDomain}
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.addWorkingLocationsDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.coconala.protobuf.domain.WorkingLocationsDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse} returns this
 */
proto.coconala.protobuf.gw.auth.ListDesiredWorkingConditionItemsResponse.prototype.clearWorkingLocationsDomainsList = function() {
  return this.setWorkingLocationsDomainsList([]);
};


goog.object.extend(exports, proto.coconala.protobuf.gw.auth);
