// package: coconala.protobuf.gw.auth
// file: gw/auth/register_service.proto

import * as gw_auth_register_service_pb from "../../gw/auth/register_service_pb";
import * as google_protobuf_wrappers_pb from "google-protobuf/google/protobuf/wrappers_pb";
import * as domain_enumeration_entry_form_status_pb from "../../domain/enumeration/entry_form_status_pb";
import * as domain_enumeration_entry_form_purpose_type_pb from "../../domain/enumeration/entry_form_purpose_type_pb";
import * as domain_enumeration_entry_form_organization_type_pb from "../../domain/enumeration/entry_form_organization_type_pb";
import * as domain_enumeration_entry_form_inflow_route_pb from "../../domain/enumeration/entry_form_inflow_route_pb";
import * as domain_enumeration_entry_form_order_format_pb from "../../domain/enumeration/entry_form_order_format_pb";
import * as domain_enumeration_entry_form_contract_style_pb from "../../domain/enumeration/entry_form_contract_style_pb";
import * as domain_register_pb from "../../domain/register_pb";
export class RegisterService {
  static serviceName = "coconala.protobuf.gw.auth.RegisterService";
}
export namespace RegisterService {
  export class SavePreRegister {
    static readonly methodName = "SavePreRegister";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.SavePreRegisterRequest;
    static readonly responseType = gw_auth_register_service_pb.SavePreRegisterResponse;
  }
  export class UpdatePreRegisterByToken {
    static readonly methodName = "UpdatePreRegisterByToken";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.UpdatePreRegisterByTokenRequest;
    static readonly responseType = gw_auth_register_service_pb.UpdatePreRegisterByTokenResponse;
  }
  export class GetRegistrationPurposeAndPrivateCorporation {
    static readonly methodName = "GetRegistrationPurposeAndPrivateCorporation";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.GetRegistrationPurposeAndPrivateCorporationRequest;
    static readonly responseType = gw_auth_register_service_pb.GetRegistrationPurposeAndPrivateCorporationResponse;
  }
  export class UpdateRegistrationPurpose {
    static readonly methodName = "UpdateRegistrationPurpose";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.UpdateRegistrationPurposeRequest;
    static readonly responseType = gw_auth_register_service_pb.UpdateRegistrationPurposeResponse;
  }
  export class UpdatePrivateCorporation {
    static readonly methodName = "UpdatePrivateCorporation";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.UpdatePrivateCorporationRequest;
    static readonly responseType = gw_auth_register_service_pb.UpdatePrivateCorporationResponse;
  }
  export class GetPreRegisterEmail {
    static readonly methodName = "GetPreRegisterEmail";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.GetPreRegisterEmailRequest;
    static readonly responseType = gw_auth_register_service_pb.GetPreRegisterEmailResponse;
  }
  export class GetIsMzhc {
    static readonly methodName = "GetIsMzhc";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.GetIsMzhcRequest;
    static readonly responseType = gw_auth_register_service_pb.GetIsMzhcResponse;
  }
  export class SaveAddress {
    static readonly methodName = "SaveAddress";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.SaveAddressRequest;
    static readonly responseType = gw_auth_register_service_pb.SaveAddressResponse;
  }
  export class SaveCorporationInformationBuyer {
    static readonly methodName = "SaveCorporationInformationBuyer";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.SaveCorporationInformationBuyerRequest;
    static readonly responseType = gw_auth_register_service_pb.SaveCorporationInformationBuyerResponse;
  }
  export class SaveMzhcCorporationInformationBuyer {
    static readonly methodName = "SaveMzhcCorporationInformationBuyer";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.SaveMzhcCorporationInformationBuyerRequest;
    static readonly responseType = gw_auth_register_service_pb.SaveMzhcCorporationInformationBuyerResponse;
  }
  export class SaveCorporationInformationProvider {
    static readonly methodName = "SaveCorporationInformationProvider";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.SaveCorporationInformationProviderRequest;
    static readonly responseType = gw_auth_register_service_pb.SaveCorporationInformationProviderResponse;
  }
  export class SaveOrderFormat {
    static readonly methodName = "SaveOrderFormat";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.SaveOrderFormatRequest;
    static readonly responseType = gw_auth_register_service_pb.SaveOrderFormatResponse;
  }
  export class ListIndustry {
    static readonly methodName = "ListIndustry";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.ListIndustryRequest;
    static readonly responseType = gw_auth_register_service_pb.ListIndustryResponse;
  }
  export class ListEmployeeNumber {
    static readonly methodName = "ListEmployeeNumber";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.ListEmployeeNumberRequest;
    static readonly responseType = gw_auth_register_service_pb.ListEmployeeNumberResponse;
  }
  export class ListCountry {
    static readonly methodName = "ListCountry";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.ListCountryRequest;
    static readonly responseType = gw_auth_register_service_pb.ListCountryResponse;
  }
  export class GenerateUserName {
    static readonly methodName = "GenerateUserName";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.GenerateUserNameRequest;
    static readonly responseType = gw_auth_register_service_pb.GenerateUserNameResponse;
  }
  export class SaveContractStyle {
    static readonly methodName = "SaveContractStyle";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.SaveContractStyleRequest;
    static readonly responseType = gw_auth_register_service_pb.SaveContractStyleResponse;
  }
  export class ListBudget {
    static readonly methodName = "ListBudget";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.ListBudgetRequest;
    static readonly responseType = gw_auth_register_service_pb.ListBudgetResponse;
  }
  export class SaveBudget {
    static readonly methodName = "SaveBudget";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.SaveBudgetRequest;
    static readonly responseType = gw_auth_register_service_pb.SaveBudgetResponse;
  }
  export class SaveDesiredWorkingCondition {
    static readonly methodName = "SaveDesiredWorkingCondition";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.SaveDesiredWorkingConditionRequest;
    static readonly responseType = gw_auth_register_service_pb.SaveDesiredWorkingConditionResponse;
  }
  export class ListDesiredWorkingConditionItems {
    static readonly methodName = "ListDesiredWorkingConditionItems";
    static readonly service = RegisterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_auth_register_service_pb.ListDesiredWorkingConditionItemsRequest;
    static readonly responseType = gw_auth_register_service_pb.ListDesiredWorkingConditionItemsResponse;
  }
}
